/* BOOTSTRAP CUSTOM VARIABLES
------------------------------------------------------------------------*/
@import "../scss/generic/variables-bootstrap-custom";


/* VARIABLES
------------------------------------------------------------------------*/
@import "../scss/generic/variables";


/* COMPASS
------------------------------------------------------------------------*/
@import "compass";


/* BOOTSTRAP
------------------------------------------------------------------------*/
@import "../scss/vendor/bootstrap/bootstrap-custom.scss";


/* SUSY
------------------------------------------------------------------------*/
@import "susy";

* {
	background: transparent!important;
	box-shadow: none!important;
	color: #000!important;
	text-shadow: none!important;
}

:after {
	background: transparent!important;
	box-shadow: none!important;
	color: #000!important;
	text-shadow: none!important;
}

:before {
	background: transparent!important;
	box-shadow: none!important;
	color: #000!important;
	text-shadow: none!important;
}

body {
  font-family: Georgia, ‘Times New Roman’, serif;
  font-size: 12pt;
}

h1 {font-size: 30pt;}
h2 {font-size: 24pt;}
h3 {font-size: 18pt;}
h4 {font-size: 15pt;}
h5 {font-size: 12pt;}
h6 {font-size: 10pt;}

h2,h3 {
  orphans: 3;
  page-break-after: avoid;
  widows: 3;
}

img {
	max-width: 100%!important;
	page-break-inside: avoid;
}

p {
	orphans: 3;
	widows: 3;
	&.lead {
		page-break-inside: avoid;
	}
}

a {
	text-decoration: underline;
	&:visited {
		text-decoration: underline;
	}
	&:link,
	&:visited {
		background: transparent;
  	font-weight: bold;
  	text-decoration: underline;
		&:after {
			content: " (" attr(href) ") ";
  		font-size: 90%;
  	}
	}
}

a[href]:after {
  content: " (" attr(href) ") ";
}

a[href^="#"]:after,
a[href^="javascript:"]:after {
  content:"";
}

abbr[title]:after {
  content: " (" attr(title) ")";
}

abbr[title]:after {
	content: " (" attr(title) ")";
}

blockquote {
  page-break-inside: avoid;
  font-family: georgia;
  font-style: italic;
  border: none;
  margin: 0;
  padding: 0;
}

pre {
	border: 1px solid #999;
	page-break-inside: avoid;
}

thead {
	display: table-header-group;
}

tr {
	page-break-inside: avoid;
}

.control,
.mm-menu,
iframe,
.pagination,
.search-form,
.slat__media,
.btn,
.views-exposed-form,
.site__sidebar,
.navigation--quaternary,
.file > img {
  display: none !important;
}

.slat {
	margin: 0;
	border: none!important;
}

.slat__link {
	text-decoration: none!important;
	.slat__body {
		text-decoration: none!important;
	}
}

.slat__content {
	padding: 0;
	width: 100%!important;
	margin: 0!important;
}

.responsive-ratio {
	padding-bottom: 0!important;
}

.logo__brand {
	.logo--secondary & {
		fill: black;
	}
}

.logo__link {
	&:after {
		padding-top: 10px;
	}
}

.view {
	img {
  	max-width: 100%;
  	width: auto!important;
  	height: auto;
  }
}

.panel {
  clear: both;
}

.collapse {
  display: block!important;
}
