/*
 * Global Ignite Variables
 *
 * 1. Ignite custom typography settings for prose (See _prose.scss for explanation).
 *
 * 2. Establish custom spacing variables based on line height to
 *    facilitate vertical rhythm
 *
 * 3. Default outer gutter for smaller devices
 *
 * 4. Black/white colour variables
 *
 * 5. Brand visited & focused styles\
 *
 * 6. KidsCan colour palette.
 *
 * 7. Font family.
 *
 */

$line-height-base-em: 1.2375em;

/* [1] */
$line-height-base-prose: $line-height-base;
$line-height-base-prose-em: 1.2375em;
$line-height-prose-computed: $font-size-base;

/* [2] */
$spacing-base:                  $line-height-base-em;
$spacing-and-half:              $spacing-base + ($spacing-base/2);
$spacing-double:                $spacing-base * 2;
$spacing-double-and-half:       $spacing-base * 2 + ($spacing-base/2);
$spacing-triple:                $spacing-base * 3;
$spacing-three-quarters:        $spacing-base - ($spacing-base/4);
$spacing-half:                  $spacing-base / 2;
$spacing-quarter:               $spacing-base / 4;

$spacing-prose-base:            $line-height-prose-computed;
$spacing-prose-and-half:        $spacing-prose-base + ($spacing-prose-base/2);
$spacing-prose-double:          $spacing-prose-base * 2;
$spacing-prose-three-quarters:  $spacing-prose-base - ($spacing-prose-base/4);
$spacing-prose-half:            $spacing-prose-base / 2;
$spacing-prose-quarter:         $spacing-prose-base / 4;

/* [3] */
$spacing-mobile-outer-gutter: $spacing-half;

/* [4] */
$white: #ffffff;
$black: #000000;

/* [5] */
$brand-visited: #4c2c92;
$brand-focused: #abe342;

$brand-border: #e2e2e2;

/* [6] */
$blue: $brand-info; // #1a488c
$blue-dark: #153a70;
$blue-light: #5193b6;

$pink: $brand-primary; // #ee2f59

$gray-alt-dark: #373737;
$gray-alt: #58595b;
$gray-alt-light: #cccccc;
$gray-alt-lighter: #f8f9fa;

/* [7] */
$font-family-base: 'NationalRegular', arial, sans-serif;
$font-family-bold: 'NationalBold', arial, sans-serif;
$font-family-italic: 'NationalItalic', arial, sans-serif;
$font-family-bold-italic: 'NationalBoldItalic', arial, sans-serif;
$font-family-black: 'NationalBlack', arial, sans-serif;
